body,
#app {
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding-top: 0px;
	overflow: hidden;
	color: white;
	font-family: 'Varela', sans-serif;
}

.overlay-wrapper {
	width: 100px;
	border-radius: 8px;
	position: relative;
	margin-top: -16px;
	padding-left: 8px;
}

.overlay-wrapper.gamestate_menu {
	background-color: rgba(0, 0, 0, 0.85);
	left: 8px;
	top: 60px;
}

.overlay-wrapper.gamestate_game {
	padding-top: 8px;
	padding-left: 0px;
	width: 800px;
	background-color: rgba(0, 0, 0, 0.5);
}

.overlay-wrapper.overlay_postion_top {
	margin-left: auto;
	margin-right: auto;
	margin-top: 0px;
}

.overlay-wrapper.overlay_postion_bottom_left {
	position: absolute;
	bottom: 0px;
	background-color: rgba(0, 0, 0, 0.35) !important;
	width: auto !important;
	margin-right: auto;
	margin-top: 0px;
}
.overlay-wrapper.compactoverlay {
	background-color: unset !important;
}

.overlay-wrapper.overlay_postion_right,
.overlay-wrapper.overlay_postion_left {
	background-color: unset !important;
	width: 300px;
	padding: 10px;
}

.overlay-wrapper.overlay_postion_left {
	margin-left: unset !important;
	margin-right: auto !important;
}

.overlay-wrapper.overlay_postion_right {
	margin-left: auto !important;
	margin-right: unset !important;
}

.overlay-wrapper.overlay_postion_bottom_left .otherplayers {
	justify-content: left;
	align-items: left;
}

.overlay-wrapper.overlay_postion_right .otherplayers,
.overlay-wrapper.overlay_postion_left .otherplayers {
	width: 100%;
	height: 100vh;
	flex-direction: column;
}

.overlay-wrapper.overlay_postion_right .playername,
.overlay-wrapper.overlay_postion_left .playername {
    transition:opacity 400ms;
	padding-top: 3vh;
	font-weight: bold;
	padding-left: 5px;
	padding-right: 5px;
}

.overlay-wrapper.overlay_postion_right1 .playername {
	position: absolute;
	right: 9vh;
}

.overlay-wrapper.overlay_postion_left1 .playername {
	position: absolute;
	left: 9vh;
}

.overlay-wrapper.overlay_postion_right .playername small,
.overlay-wrapper.overlay_postion_left .playername small {
	background-color: rgba(0, 0, 0, 0.322);
	border-width: 10px;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0);
	border-radius: 40px;
}

.overlay-wrapper.overlay_postion_right .playername {
	float: right;
}

.overlay-wrapper.overlay_postion_left .playername {
	float: left;
}

.overlay-wrapper.overlay_postion_left .player_wrapper,
.overlay-wrapper.overlay_postion_right .player_wrapper {
	width: 100%;
}

.player_wrapper {
	text-align: center;
}

.overlay-wrapper .player_wrapper > div:first-of-type {
	width: 60px;
	float: unset;
}
.overlay-wrapper.overlay_postion_right .player_wrapper > div:first-of-type,
.overlay-wrapper.overlay_postion_left .player_wrapper > div:first-of-type {
	width: var(--size) !important;
	max-width: 7.5vh;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 1px;
}

.overlay-wrapper.overlay_postion_right .player_wrapper > div:first-of-type {
	float: right;
}

.overlay-wrapper.overlay_postion_left .player_wrapper > div:first-of-type {
	float: left;
}

.overlay-wrapper.compactoverlay.overlay_postion_left .player_wrapper > div:first-of-type {
	padding-right: 0px;

}
.overlay-wrapper.compactoverlay.overlay_postion_right .player_wrapper > div:first-of-type {
	padding-left: 0px;
	float: left !important;

}

.otherplayers {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.players_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
}

.players_container > * {
	margin: 5px;
}

.playerContainerBack_left {
	background-color: #25232ac0;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
}

.playerContainerBack_right {
	background-color: #25232ac0;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
}

.overlay_postion_left.compactoverlay,
.overlay_postion_right.compactoverlay {
	width: 9vh !important;
}

.overlay_postion_left.compactoverlay {
	padding-left: 0px !important;
}

.overlay_postion_right.compactoverlay {
	padding-right: 0px !important;
}

.overlay_postion_left.compactoverlay > .otherplayers > .players_container {
	background-color: #25232ac0;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
	padding: 5px;
}

.overlay_postion_right.compactoverlay > .otherplayers > .players_container {
	background-color: #25232ac0;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	padding: 5px;
}


.volumeicons{
	position: absolute;
	left:0;
	bottom: 0;
	background-color: #25232ac0;
	padding:4px;
	border-top-right-radius: 10px;
}